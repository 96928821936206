import React from 'react';
import { Helmet } from 'react-helmet';
import { renderToString } from 'react-dom/server';

const remapQuestion = ({ name, reactElement }) => {
  let elementToString;
  try {
    elementToString = renderToString(reactElement);
  } catch {
    elementToString = '';
  }
  return {
    '@type': 'Question',
    name,
    acceptedAnswer: {
      '@type': 'Answer',
      text: elementToString,
    },
  };
};

export const getFAQSchema = (items = []) => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'FAQPage',
        mainEntity: items
          .filter(({ content }) => !!content)
          .map(({ header: name, content }) => remapQuestion({ name, reactElement: content })),
      })}
    </script>
  </Helmet>
);
