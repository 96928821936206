import React from 'react';

import { Accordion, AccordionGroup, Box } from '@core';
import { SectionContainerSC as SectionContainer } from '@components';
import { getFAQSchema } from '@shared/seo-helper';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

import { Content, Header } from './components';

const FAQSection = ({
  title,
  description,
  initiallyOpenedItem = null,
  items = [],
  padding = { py: { _: 64, md: 88 } },
}) => (
  <SectionContainer
    data-section-id="FAQ"
    title={title}
    description={description}
    wrapperProps={{ ...padding }}
  >
    {getFAQSchema(items)}
    <Box display="flex" justifyContent="center" px={{ _: 24, md: 40 }}>
      <Box width="100%" maxWidth={880}>
        <AccordionGroup
          allowZeroExpanded
          initiallyOpenedItem={initiallyOpenedItem}
          accordion={Accordion}
          data={items.map(
            ({ header, content, listLabel, listItems, buttonLink, buttonLabel, withBullets }) => {
              const accordionHeaderId = header?.replace(/\W/g, '_').toLowerCase();
              return {
                header: ({ isOpen }) => (
                  <Header id={accordionHeaderId} isOpen={isOpen} title={header} />
                ),
                content: () => (
                  <Content
                    accordionHeaderId={accordionHeaderId}
                    description={content}
                    buttonLink={buttonLink}
                    buttonLabel={buttonLabel}
                    listLabel={listLabel}
                    listItems={listItems}
                    withBullets={withBullets}
                  />
                ),
              };
            }
          )}
          onOpen={(item) => trackCustomGA4Event({ eventName: GA4_EVENTS.faqItemClick(item + 1) })}
        />
      </Box>
    </Box>
  </SectionContainer>
);

export default FAQSection;
