import React from 'react';

import { Box } from '@core';
import { Animation, Icon, Text } from '@components';

const Header = ({ isOpen, title, id }) => (
  <Box id={id} display="flex" justifyContent="space-between" width="100%" mb={16}>
    <Text.Body1Strong textAlign="left">{title}</Text.Body1Strong>
    <Box pt={6} pl={24}>
      <Animation.Rotate rotate={isOpen}>
        <Box display="flex" alignItems="center">
          <Icon.Down />
        </Box>
      </Animation.Rotate>
    </Box>
  </Box>
);

export default Header;
