import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Box, Button, Grid } from '@core';
import { Text } from '@components';

// @TODO maybe create a separate List component
const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
`;

const Content = ({
  accordionHeaderId,
  description,
  listLabel,
  listItems,
  buttonLink,
  buttonLabel,
  withBullets = true,
}) => {
  useEffect(() => {
    window.scroll({
      top: document.getElementById(accordionHeaderId).offsetTop - 200,
      behavior: 'smooth',
    });
  }, [accordionHeaderId]);
  return (
    <Grid.Container mb={40}>
      {description && (
        <Grid.Row>
          <Text.Body2 as="div" whiteSpace="pre-wrap" textAlign="left" color="darkBlue">
            {description}
          </Text.Body2>
        </Grid.Row>
      )}

      {listLabel && (
        <Grid.Row>
          <Grid.Col>
            <Text.Body2 textAlign="left" color="darkBlue">
              {listLabel}
            </Text.Body2>
          </Grid.Col>
          <Grid.Col>
            <List>
              {listItems.map((item) => (
                <Box display="flex" key={item}>
                  {withBullets && (
                    <Box height={32} display="flex" px={8}>
                      <Box
                        width={8}
                        height={8}
                        borderRadius="sm"
                        alignSelf="center"
                        backgroundColor="darkBlue"
                      />
                    </Box>
                  )}
                  <Text.Body2 as="li" textAlign="left" color="darkBlue">
                    {item}
                  </Text.Body2>
                </Box>
              ))}
            </List>
          </Grid.Col>
        </Grid.Row>
      )}

      {buttonLink && (
        <Grid.Row mt={8}>
          <Link to={buttonLink}>
            <Button as="span" variant="text">
              {buttonLabel}
            </Button>
          </Link>
        </Grid.Row>
      )}
    </Grid.Container>
  );
};

export default Content;
